import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import * as Actions from '../state/app'
import { theme, media } from '../styles'
const { fonts, fontSizes } = theme

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: auto;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
`

const Title = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  h2 {
    color: ${props => props.color};
    font-family: ${fonts.accent};
    font-size: ${fontSizes.largest};
    font-weight: 100;
    letter-spacing: 2px;
    margin: 0 0 30px 0;
  }
  ${media.thone`
    h2 {
      font-size: ${fontSizes.superbig};
      }
  `}
`

const FormOuter = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  width: 500px;
  p, label {
    font-family: ${fonts.heading};
    color: ${props => props.color};
    font-size: ${fontSizes.medium};
  }

  label {
    margin-right: 25px;
  }
  ${media.thone`
    width: 80vw;
  `}
`

const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;

  label {
    width: 30%;
  }
`

const FormInput = styled.div.attrs(({ shade, text }) => ({
  color: shade || '#FFFFFF',
}))`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  input {
    border-style:solid;
  }

  option, textarea {
    font-size: ${fontSizes.medium};
  }

  input, textarea, option, select {
    width: 100%;
    border-width: 1px;
    min-height: 20px;
    border-radius: 0px !important;
  }
`

const Button = styled.button.attrs(({ shade, text }) => ({
  color: shade || '#FFFFFF',
  text: text || '#FFFFFF',
}))`
  cursor: pointer;
  background: ${props => props.color};
  padding: 20px 30px;
  z-index: 60;
  display: inline-block;

  p {
    margin: 0;
    color: ${props => props.text};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: ${theme.fonts.body};
    font-size: ${theme.fontSizes.medium};
  }
`

const FormMessage = styled.p.attrs(({ shade, text }) => ({
  color: shade || '#FFFFFF',
}))`
  color: ${props => props.color};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.medium};
  text-align: left;
  padding: 0 20px;
  line-height: 1.5;
`

export class ArtTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
    }

  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  };

  render() {
    const { colors: { text, background, dark, shadow, accent } } = theme

    return (
      <Container>
        <Title shade={text}>
            <h2>request</h2>
          </Title>
        <FormOuter shade={text}>
        <FormMessage>please fill out the following including any extra requests or customizations. we will get back to you as soon as possible!</FormMessage>
        <form name="request" method="POST" data-netlify="true">
          <input type="hidden" name="form-name" value="request" />
          <FormRow>
            <label>name </label>
            <FormInput shade={accent}>
              <input type="text" name="name" onChange={this.handleChange} />
            </FormInput>
          </FormRow>
          <FormRow>
            <label>email</label>
            <FormInput shade={accent}>
              <input type="email" name="email" onChange={this.handleChange} />
            </FormInput>
          </FormRow>
          <FormRow>
            <label>zodiac</label>
            <FormInput shade={accent}>
              <select name="zodiac" multiple onChange={this.handleChange} >
                <option value="aries" defaultValue>aries</option>
                <option value="taurus">taurus</option>
                <option value="gemini">gemini</option>
                <option value="cancer">cancer</option>
                <option value="leo">leo</option>
                <option value="virgo">virgo</option>
                <option value="libra">libra</option>
                <option value="scorpio">scorpio</option>
                <option value="sagittarius">sagittarius</option>
                <option value="capricorn">capricorn</option>
                <option value="aquarius">aquarius</option>
                <option value="pisces">pisces</option>
              </select>
            </FormInput>
          </FormRow>
          <FormRow>
            <label>extra request</label>
            <FormInput shade={accent}>
              <textarea name="extra" onChange={this.handleChange}></textarea>
            </FormInput>
          </FormRow>
          <FormRow>
            <Button shade={dark} text={background} type="submit">
              <p>submit</p>
            </Button>
          </FormRow>
        </form>
        <FormMessage shade={accent}>{this.state.message}</FormMessage>
        </FormOuter>
      </Container>
    )
  }
}

function mapStateToProps({ displayNav }) {
  return { displayNav }
}

function mapDispatchToProps(dispatch) {
  return {
    toggle: () => { dispatch(Actions.toggleNav()) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtTemplate)
